import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { updateHawkerCentreRequest, setSelectedCentre } from "../actions";

import Form from "./Forms/updateCentreForm";

export default function CentreInformation() {
  const { id } = useParams();
  const [centre, setCentre] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigateToAddStallForm = () => {
    dispatch(setSelectedCentre(centre.id));
    navigate(`/stalls`);
  };

  const hawkerCentres = useSelector(
    (state) => state.hawkerCentres.hawkerCentres
  );

  const updateHawkerCentreHandler = (
    name,
    address,
    neighbourhood,
    postalCode,
    image
  ) => {
    dispatch(
      updateHawkerCentreRequest({
        name,
        address,
        neighbourhood,
        postalCode,
        image,
        id,
      })
    );
  };

  useEffect(() => {
    if (id && hawkerCentres && hawkerCentres.length) {
      const centre = hawkerCentres.filter(
        (centre) => centre.id === parseInt(id)
      );
      setCentre(centre[0]);
    }
  }, [hawkerCentres, id]);

  return (
    <div style={{ textAlign: "center" }}>
      <h1 style={{ textAlign: "left" }}>
        Hawker Centre - {centre && centre.name}
      </h1>
      <Form
        centreDetails={centre}
        updateCentreHandler={updateHawkerCentreHandler}
      />
      <div>
        <button onClick={navigateToAddStallForm}>Add Stall</button>
      </div>
    </div>
  );
}
