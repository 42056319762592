import React from "react";
import styled from "styled-components";

const CardContainer = styled.div`
  width: 800px;
  height: 150px;
  display: flex;
  border: 1px solid black;
  border-radius: 5px;
  margin: 20px 0;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);

  &:hover {
    cursor: pointer;
    border: 2px solid blue;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 13px 0 13px 18px;
`;

const ImageContainer = styled.div`
  width: 150px;
`;

export default function Card({
  id,
  image,
  full_address,
  region,
  neighbourhood,
  name,
  selectHawkerCentreHandler,
}) {
  const selectHawkerHandler = (id) => selectHawkerCentreHandler(id);

  return (
    <CardContainer onClick={() => selectHawkerHandler(id)}>
      <ImageContainer>
        <img src={image} alt={name} width="150x" height="150px" />
      </ImageContainer>
      <ContentContainer>
        <span>
          <h3>{name}</h3>
        </span>
        <span>
          <p>{full_address}</p>
        </span>
        <span>
          <p style={{ color: "grey" }}>
            {region} · {neighbourhood}
          </p>
        </span>
      </ContentContainer>
    </CardContainer>
  );
}
