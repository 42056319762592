import { createReducer } from "@reduxjs/toolkit";
import {
  getAllCentresRequest,
  getAllCentresSuccess,
  getAllCentresFailure,
  updateHawkerCentreRequest,
  updateHawkerCentreSuccess,
  updateHawkerCentreFailure,
  setSelectedCentre,
} from "./actions";

const initialState = {
  hawkerCentres: [],
  selectedCentre: null,
  isLoading: false,
  selectedCentreId: null,
};
export const hawkerCentresReducer = createReducer(initialState, (build) => {
  build
    .addCase(getAllCentresRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(getAllCentresSuccess, (state, action) => {
      state.isLoading = false;
      state.hawkerCentres = action.payload;
    })
    .addCase(getAllCentresFailure, (state) => {
      state.isLoading = false;
      state.hawkerCentres = [];
      state.selectedCentre = null;
    })
    .addCase(updateHawkerCentreRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(updateHawkerCentreSuccess, (state, action) => {
      state.isLoading = false;
      state.selectedCentre = action.payload;
    })
    .addCase(updateHawkerCentreFailure, (state) => {
      state.isLoading = false;
      state.hawkerCentres = [];
      state.selectedCentre = null;
    })
    .addCase(setSelectedCentre, (state, action) => {
      state.selectedCentreId = action.payload;
    });
});
