import React from "react";
import styled from "styled-components";

const HeaderBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  background: #94b49f;
  box-shadow: 1px 0 2px rgba(0, 0, 0, 0.125);
  display: flex;
  align-items: center;
  z-index: 1;
`;

export default function Header() {
  return <HeaderBar />;
}
