import React, { useEffect, useState } from "react";
import styled from "styled-components";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import Modal from "../../../components/Modal";
import { formatInput } from "../../../helpers/format";

import { TextField, Button } from "@material-ui/core";

const FormContainer = styled.form`
  width: 65%;
  height: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

const schema = yup.object().shape({
  name: yup
    .string()
    .required("Please enter a valid name for the hawker centre."),
  floor: yup.string().max(6).required("Please enter a valid floor."),
  unit: yup.string().max(6).required("Please enter a valid stall unit."),
  centre: yup.string().required(),
});

export default function StallForm({ addStall }) {
  const [centreId, setCentreId] = useState(1);
  const [showModal, setShowModal] = useState(false);

  const selectedCentreId = useSelector(
    (state) => state.hawkerCentres.selectedCentreId
  );

  const modalClickHandler = () => setShowModal(!showModal);

  const centresArray = useSelector((state) =>
    state.hawkerCentres.hawkerCentres.map((hawker) => {
      const obj = {};
      obj["id"] = hawker.id;
      obj["name"] = hawker.name;
      return obj;
    })
  );

  const selectChangeHandler = (e) => {
    setCentreId(e.target.value);
  };

  useEffect(() => {
    if (selectedCentreId) {
      setCentreId(selectedCentreId);
    }
  }, [selectedCentreId]);

  const submitFormHandler = (data) => {
    modalClickHandler();
    const imgFile = data.image[0];
    const { name, floor, unit } = data;

    addStall({ centreId, name, floor, unit, image: imgFile });
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

  const formInput = watch();

  const formValidationStatus =
    Object.keys(errors).length === 0 && !Object.values(formInput).includes("");

  return (
    <FormContainer onSubmit={handleSubmit(submitFormHandler)}>
      <h1>Add Stall</h1>
      <div style={{ marginTop: "20px" }} />
      <TextField
        variant="outlined"
        label="Name"
        fullWidth
        autoFocus
        {...register("name")}
      />
      {errors && errors.name && (
        <p style={{ color: "red" }}>{errors.name.message}</p>
      )}
      <div style={{ marginTop: "20px" }} />
      <TextField
        variant="outlined"
        label="Floor"
        fullWidth
        autoFocus
        {...register("floor")}
      />
      {errors && errors.floor && (
        <p style={{ color: "red" }}>{errors.floor.message}</p>
      )}
      <div style={{ marginTop: "20px" }} />
      <TextField
        variant="outlined"
        label="Unit"
        fullWidth
        autoFocus
        {...register("unit")}
      />
      {errors && errors.unit && (
        <p style={{ color: "red" }}>{errors.unit.message}</p>
      )}
      <div style={{ marginTop: "20px" }} />
      <label>Centres</label>
      <select
        label="Centre"
        value={centreId}
        {...register("centre")}
        onChange={selectChangeHandler}
      >
        {centresArray &&
          centresArray.length &&
          centresArray.map((centre) => (
            <option key={centre.id} value={centre.id}>
              {centre.name}
            </option>
          ))}
      </select>
      <div style={{ marginTop: "20px" }} />
      <img src="" alt="" width="300px" height="300px" />
      <div style={{ marginTop: "20px" }} />
      <TextField
        variant="outlined"
        type="file"
        accept="image/*"
        fullWidth
        autoFocus
        {...register("image")}
      />
      <Button
        onClick={modalClickHandler}
        variant="contained"
        color="primary"
        disabled={!formValidationStatus}
      >
        Add New Stall
      </Button>

      {showModal && (
        <Modal
          closeModal={modalClickHandler}
          onSubmit={submitFormHandler}
          handleSubmit={handleSubmit}
          currentInput={formatInput(formInput)}
        />
      )}
    </FormContainer>
  );
}
