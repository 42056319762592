import { configureStore } from "@reduxjs/toolkit";
import saga from "redux-saga";
import rootSaga from "./sagas";
import { hawkerCentresReducer } from "./pages/Centres/reducer";
import { hawkerStallReducer } from "./pages/Stalls/reducer";
const sagaMiddleware = saga();

export default function configureAppStore() {
  const store = configureStore({
    reducer: {
      hawkerCentres: hawkerCentresReducer,
      hawkerStalls: hawkerStallReducer,
    },
    middleware: [sagaMiddleware],
    devTools: process.env.NODE_ENV !== "production",
  });

  sagaMiddleware.run(rootSaga);

  return store;
}
