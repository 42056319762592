/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getAllCentresRequest } from "./actions";
import { debounce } from "lodash";
import { selectAllHawkerCentres, selectLoadingState } from "./selectors";
import { TextField } from "@material-ui/core";

import Card from "../../components/Card";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useNavigate } from "react-router-dom";

export function Centres({ getAllCentres, hawkerCentres, isLoading }) {
  const [input, setInput] = useState("");
  useEffect(() => {
    getAllCentres();
  }, []);

  const searchCentreHandler = (e) => {
    setInput(e.target.value);
    delayChangeHandler(e.target.value);
  };

  const delayChangeHandler = debounce((inputVal) => {
    getAllCentres(inputVal);
  }, 500);

  // Search by neighbourhood
  // Search by region
  // Throttle function
  const navigate = useNavigate();
  const selectHawkerCentreHandler = (id) => {
    navigate(`/centre-information/${id}`);
  };

  return (
    <div
      style={{
        margin: "0 auto",
        marginBottom: "50px",
        marginTop: "50px",
        overflow: "auto",
        maxHeight: "80vh",
      }}
    >
      <div style={{ marginTop: "30px" }}>
        <TextField
          variant="outlined"
          label="Search by Hawker Centre"
          value={input}
          autoFocus
          style={{ width: "300px" }}
          onChange={searchCentreHandler}
        />
        {!isLoading && hawkerCentres.length < 1 && (
          <h1>Unfortunately, no such hawker centre exists ...</h1>
        )}
        {isLoading ? (
          <div style={{ margin: "0 auto" }}>
            <LoadingSpinner />
          </div>
        ) : (
          <>
            {hawkerCentres.map((centre) => (
              <Card
                key={centre.id}
                id={centre.id}
                image={centre.image}
                name={centre.name}
                neighbourhood={centre.neighbourhood}
                region={centre.region}
                full_address={centre.full_address}
                selectHawkerCentreHandler={selectHawkerCentreHandler}
              />
            ))}
          </>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  hawkerCentres: selectAllHawkerCentres(state),
  isLoading: selectLoadingState(state),
});

const mapDispatchToProps = (dispatch) => ({
  getAllCentres: (input) => dispatch(getAllCentresRequest(input)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Centres);
