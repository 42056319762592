import { takeLatest, put, call } from "redux-saga/effects";
import { Hawkers } from "../../api/hawkerCentres";
import { Cloudinary } from "../../api/cloudinaryUpload";
import {
  getAllCentresRequest,
  getAllCentresSuccess,
  getAllCentresFailure,
  updateHawkerCentreRequest,
  updateHawkerCentreSuccess,
  updateHawkerCentreFailure,
} from "./actions";

export function* getHawkerCentresSaga({ payload }) {
  try {
    const response = yield call(Hawkers.getAllCentres, { payload });
    yield put(getAllCentresSuccess(response.data));
  } catch (err) {
    getAllCentresFailure(err);
  }
}

export function* updateHawkerCentreSaga({ payload }) {
  try {
    const { name, image, address, neighbourhood, postalCode, id } = payload;

    let imageUploadResponse;

    if (image) {
      imageUploadResponse = yield call(
        Cloudinary.uploadImageToCloudinary,
        image
      );
    }
    const payloadObject = {
      name,
      image: image ? imageUploadResponse.secure_url : null,
      full_address: address,
      neighbourhood,
      postal_code: postalCode,
    };

    const response = yield call(Hawkers.updateHawkerCentre, {
      payloadObject,
      id,
    });
    yield put(updateHawkerCentreSuccess(response.data[0]));
  } catch (err) {
    updateHawkerCentreFailure(err);
  }
}

export default function* hawkerCentresSaga() {
  yield takeLatest(getAllCentresRequest.type, getHawkerCentresSaga);
  yield takeLatest(updateHawkerCentreRequest.type, updateHawkerCentreSaga);
}
