import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addStallRequest } from "./actions";
import { selectLoadingState, selectHawkerStall } from "./selectors";
import AddStallForm from "./Forms/addStallForm";
import LoadingSpinner from "../../components/LoadingSpinner";

export function Stalls({ addStall, isLoading, addedHawkerStall }) {
  const [stallName, setStallName] = useState(null);

  useEffect(() => {
    if (addedHawkerStall && addedHawkerStall.length)
      setStallName(addedHawkerStall[0].name);
  }, [addedHawkerStall]);

  return (
    <div style={{ marginTop: "80px" }}>
      {stallName && (
        <p>
          You have just added the stall <b>{stallName}</b>!
        </p>
      )}
      {isLoading ? <LoadingSpinner /> : <AddStallForm addStall={addStall} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  isLoading: selectLoadingState(state),
  addedHawkerStall: selectHawkerStall(state),
});

const mapDispatchToProps = (dispatch) => ({
  addStall: (payload) => dispatch(addStallRequest(payload)),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default withConnect(Stalls);
