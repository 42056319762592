import { createAction } from "@reduxjs/toolkit";

export const getAllCentresRequest = createAction("getAllCentresRequest");
export const getAllCentresSuccess = createAction("getAllCentresSuccess");
export const getAllCentresFailure = createAction("getAllCentresFailure");

export const updateHawkerCentreRequest = createAction(
  "updateHawkerCentreRequest"
);
export const updateHawkerCentreSuccess = createAction(
  "updateHawkerCentreSuccess"
);
export const updateHawkerCentreFailure = createAction(
  "updateHawkerCentreFailure"
);

export const setSelectedCentre = createAction("setSelectedCentre");
