import { createSelector } from "@reduxjs/toolkit";

export const selectHawkerStalls = (state) => state.hawkerStalls;

export const selectLoadingState = createSelector(
  selectHawkerStalls,
  (stalls) => stalls.isLoading
);

export const selectHawkerStall = createSelector(
  selectHawkerStalls,
  (stalls) => stalls.hawkerStall
);
