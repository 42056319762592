import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import GlobalStyle from "./globalStyles";
import { ThemeProvider } from "styled-components";
import theme from "./styles/theme";
import Layout from "./components/Layout/Layout";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import configureAppStore from "./configureStore";
import { AxiosInterceptor } from "./api/axiosHelpers";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={configureAppStore()}>
    <React.StrictMode>
      <GlobalStyle />
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <AxiosInterceptor>
            <Layout>
              <App />
            </Layout>
          </AxiosInterceptor>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
