import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const SideBarContainer = styled.div`
  position: fixed;
  top: 60px;
  left: 0;
  width: 220px;
  height: 100%;
  background-color: ${(props) => props.theme.main};
`;

const NavContainer = styled.nav`
  height: 150px;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px;
  font-size: 20px;
`;

const NavLinks = styled(Link)`
  text-decoration: none;
  color: #293462;

  &:hover {
    cursor: pointer;
  }
  &:focus {
    color: #7a4069;
  }
`;

const LogoContainer = styled.div`
  height: 80px;
  border-bottom: 2px solid #5a8f7b;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default function SideBar() {
  return (
    <SideBarContainer>
      <LogoContainer>
        <NavLinks to="/">
          <h2>PLUS 65 LOGO</h2>
        </NavLinks>
      </LogoContainer>
      <NavContainer>
        <NavLinks to="/">Centres</NavLinks>
        <NavLinks to="/stalls">Stalls</NavLinks>
        <NavLinks to="/dishes">Dishes</NavLinks>
      </NavContainer>
    </SideBarContainer>
  );
}
