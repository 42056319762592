import React from 'react'
import { Routes, Route } from "react-router-dom";
import Centres from "./pages/Centres";
import Stalls from "./pages/Stalls";
import Dishes from "./pages/Dishes";
import SystemError from "./pages/Error";
import CentreInformation from "./pages/Centres/CentreInformation";
// import AddStallForm from "./pages/Centres/CentreInformation/Forms/addStallForm";

function App() {
  return (
    <div style={{ marginTop: "80px" }}>
      <Routes>
        <Route path="/" element={<Centres />} />
        <Route path="/centre-information/:id" element={<CentreInformation />} />
        {/* <Route path="/add-stall/:centreId" element={<AddStallForm />} /> */}
        <Route path="stalls" element={<Stalls />} />
        <Route path="dishes" element={<Dishes />} />
        <Route path="system-error" element={<SystemError />} />
      </Routes>
    </div>
  );
}

export default App;
