const uploadImageToCloudinary = async (file, options) => {
  const CLOUDINARY_URL =
    "https://api.cloudinary.com/v1_1/plus-six-five/image/upload";
  const CLOUDINARY_UPLOAD_PRESET = "primary";

  const formData = new FormData();
  formData.append("file", file);
  formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
  if (options?.folder) {
    formData.append("folder", options.folder);
  }
  let response = await fetch(CLOUDINARY_URL, {
    method: "POST",
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => {
      let { public_id, secure_url } = data;
      return { isSuccessful: true, public_id, secure_url };
    })
    .catch((err) => {
      console.error(err);
    });

  return response;
};

export const Cloudinary = {
  uploadImageToCloudinary,
};
