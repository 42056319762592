import { takeLatest, put, call } from "redux-saga/effects";
import { Stalls } from "../../api/hawkerStalls";

import { Cloudinary } from "../../api/cloudinaryUpload";

import { addStallRequest, addStallSuccess, addStallFailure } from "./actions";

export function* addStallSaga({ payload }) {
  try {
    const { centreId, name, floor, unit, image } = payload;

    let imageUploadResponse;

    if (image) {
      imageUploadResponse = yield call(
        Cloudinary.uploadImageToCloudinary,
        image
      );
    }

    const payloadObject = {
      centreId,
      name,
      image: image ? imageUploadResponse.secure_url : null,
      floor,
      unit,
    };

    const response = yield call(Stalls.addHawkerStall, { payloadObject });
    yield put(addStallSuccess(response.data));
  } catch (err) {
    yield put(addStallFailure(err));
  }
}

export default function* hawkerStallsSaga() {
  yield takeLatest(addStallRequest.type, addStallSaga);
}
