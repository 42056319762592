import { axios } from "./axiosHelpers";

const baseURL = process.env.REACT_APP_BASE_URL;
const microserviceUrl = `${baseURL}/api/v1/hawker-centres`;

const getAllCentres = ({ payload }) =>
  axios.get(`${microserviceUrl}/?search=${payload ? payload : ""}`);
const updateHawkerCentre = (payload) => {
  const { payloadObject, id } = payload;
  return axios.put(`${microserviceUrl}/${id}`, payloadObject);
};
export const Hawkers = {
  getAllCentres,
  updateHawkerCentre,
};
