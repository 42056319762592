import { createSelector } from "@reduxjs/toolkit";

export const selectHawkerCentres = (state) => state.hawkerCentres;

export const selectAllHawkerCentres = createSelector(
  selectHawkerCentres,
  (hawkers) => hawkers.hawkerCentres
);

export const selectLoadingState = createSelector(
  selectHawkerCentres,
  (hawkers) => hawkers.isLoading
);

export const selectSelectedCentre = createSelector(
  selectHawkerCentres,
  (hawkers) => hawkers.selectedCentre
);

