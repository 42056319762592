import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalMain = styled.div`
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  padding: 30px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default function Modal({
  closeModal,
  onSubmit,
  handleSubmit,
  currentInput,
}) {
  console.log("CURRENT INPUT: ", currentInput);
  return (
    <ModalContainer>
      <ModalMain>
        <h1>Please confirm that you'd like to make the following changes.</h1>
        {currentInput &&
          currentInput.length > 0 &&
          currentInput.map((input, index) => {
            if (input[0] === "image")
              return (
                <p key={index} style={{ textDecoration: "underline" }}>
                  <b>{input[0].toUpperCase()}</b> : {input[1][0].name}
                </p>
              );
            return (
              <p key={index} style={{ textDecoration: "underline" }}>
                <b>{input[0].toUpperCase()}</b> : {input[1]}
              </p>
            );
          })}
        <Button onClick={closeModal} variant="contained" color="secondary">
          Cancel
        </Button>
        <span style={{ marginLeft: "10px" }} />
        <Button
          onClick={handleSubmit(onSubmit)}
          type="submit"
          variant="contained"
          color="primary"
        >
          Yes, submit
        </Button>
      </ModalMain>
    </ModalContainer>
  );
}
