import React from "react";
import styled from "styled-components";
import { Button } from "@material-ui/core";
import { useNavigate } from "react-router";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1;
`;

const ModalMain = styled.div`
  position: fixed;
  background: white;
  width: 80%;
  height: auto;
  top: 50%;
  left: 50%;
  padding: 30px;
  text-align: center;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export default function SystemError() {
  const navigate = useNavigate();
  return (
    <ModalContainer>
      <ModalMain>
        <h1>Oops, it seems like an error occured.</h1>
        <Button
          onClick={() => navigate("/")}
          variant="contained"
          color="secondary"
        >
          Try Again
        </Button>
        <span style={{ marginLeft: "10px" }} />
      </ModalMain>
    </ModalContainer>
  );
}
