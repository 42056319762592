import styled from "styled-components";
import SideBar from "./SideBar";
import HeaderBar from "./Header";

const MainContainer = styled.div`
  /* display: flex;
  height: 100vh;
  width: 100vw; */
`;

const MainBody = styled.div`
  margin-left: 300px;
`;

// const CompanyLogo = styled.div`
//   width: 250px;
//   font-size: 25px;
//   font-weight: 700;
//   padding: 0 25px;
//   color: white;
//   letter-spacing: 2px;
//   text-transform: uppercase;
//   border-right: 1px solid #f5f5f5;
// `;

export default function Layout(props) {
  return (
    <MainContainer>
      <HeaderBar />
      <SideBar />
      <MainBody>{props.children}</MainBody>
    </MainContainer>
  );
}
