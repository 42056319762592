import axios from "axios";
import { useEffect } from "react";
import { useNavigate } from "react-router";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

axiosInstance.interceptors.request.use((config) => {
  return config;
});

const AxiosInterceptor = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        navigate("/system-error");
        console.log("ERROR IN INTERCEPTOR", err);
        return Promise.reject(err);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return children;
};

export { axiosInstance as axios, AxiosInterceptor };
