import { createReducer } from "@reduxjs/toolkit";
import { addStallRequest, addStallSuccess, addStallFailure } from "./actions";

const initialState = {
  isLoading: false,
  hawkerStall: [],
};
export const hawkerStallReducer = createReducer(initialState, (build) => {
  build
    .addCase(addStallRequest, (state) => {
      state.isLoading = true;
    })
    .addCase(addStallSuccess, (state, action) => {
      state.isLoading = false;
      state.hawkerStall = action.payload;
    })
    .addCase(addStallFailure, (state) => {
      state.isLoading = false;
    });
});
