import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { formatInput } from "../../../../helpers/format";
import { selectLoadingState, selectSelectedCentre } from "../../selectors";

import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";
import Modal from "../../../../components/Modal";
import LoadingSpinner from "../../../../components/LoadingSpinner";

const FormContainer = styled.form`
  width: 80%;
  height: 100%;
  padding: 30px;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 280px;
`;

const TextFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;

  @media (min-width: 992px) {
    width: 400px;
  }
`;

export function Form({
  centreDetails,
  updateCentreHandler,
  isLoading,
  selectedCentre,
}) {
  const [centreInfo, setCentreInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { register, handleSubmit, watch } = useForm({});

  useEffect(() => {
    if (selectedCentre) {
      setCentreInfo(selectedCentre);
    }
  }, [selectedCentre]);

  useEffect(() => {
    if (centreDetails) setCentreInfo(centreDetails);
  }, [centreDetails]);

  const modalClickHandler = () => setShowModal(!showModal);

  const submitFormHandler = (data) => {
    modalClickHandler();
    const imgFile = data.image[0];
    const { name, address, neighbourhood, postalCode } = data;
    updateCentreHandler(name, address, neighbourhood, postalCode, imgFile);
  };

  const currentInput = watch();

  if (!centreInfo || isLoading) return <LoadingSpinner />;

  const { name, full_address, postal_code, neighbourhood, image } = centreInfo;

  return (
    <FormContainer onSubmit={handleSubmit(submitFormHandler)}>
      <TextFieldContainer>
        <TextField
          variant="outlined"
          label="name"
          fullWidth
          defaultValue={name}
          autoFocus
          {...register("name")}
        />
        <div style={{ margin: "20px 0" }} />
        <TextField
          variant="outlined"
          label="address"
          type="text"
          defaultValue={full_address}
          fullWidth
          autoFocus
          {...register("address")}
        />
        <div style={{ margin: "20px 0" }} />
        <TextField
          variant="outlined"
          label="postal code"
          defaultValue={postal_code}
          type="text"
          fullWidth
          autoFocus
          {...register("postalCode")}
        />

        <div style={{ margin: "20px 0" }} />
        <TextField
          variant="outlined"
          label="neighbourhood"
          type="text"
          defaultValue={neighbourhood}
          fullWidth
          autoFocus
          {...register("neighbourhood")}
        />
        <div style={{ margin: "20px 0" }} />

        <Button
          onClick={modalClickHandler}
          variant="contained"
          color="primary"
          fullWidth
        >
          Edit Hawker
        </Button>
      </TextFieldContainer>
      <ImageContainer>
        <img src={image} alt={name} />
        <TextField
          variant="outlined"
          type="file"
          accept="image/*"
          fullWidth
          autoFocus
          {...register("image")}
        />
      </ImageContainer>
      {showModal && (
        <Modal
          closeModal={modalClickHandler}
          onSubmit={submitFormHandler}
          handleSubmit={handleSubmit}
          currentInput={formatInput(currentInput)}
        />
      )}
    </FormContainer>
  );
}

const mapStateToProps = (state) => ({
  selectedCentre: selectSelectedCentre(state),
  isLoading: selectLoadingState(state),
});

const withConnect = connect(mapStateToProps, null);

export default withConnect(Form);
