import { axios } from "./axiosHelpers";

const baseURL = process.env.REACT_APP_BASE_URL;
const microserviceUrl = `${baseURL}/api/v1/hawker-stalls`;

const addHawkerStall = ({ payloadObject }) =>
  axios.post(microserviceUrl, payloadObject);

export const Stalls = {
  addHawkerStall,
};
